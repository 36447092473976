import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Spinner } from "./components/Spinner";
import AuthProvider from "./context/AuthContext";
import ClaimDetailsProvider from "./context/ClaimContext";
import PatientProvider from "./context/PatientClaim";
import ServiceProvider from "./context/serviceContext";
import TransactionTotalProvider from "./context/transactionTotalContext";

const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
	<React.StrictMode>
		<AuthProvider>
			<PatientProvider>
				<ClaimDetailsProvider>
					<ServiceProvider>
						<TransactionTotalProvider>
							<Suspense fallback={<Spinner />}>
								<LazyApp />
							</Suspense>
						</TransactionTotalProvider>
					</ServiceProvider>
				</ClaimDetailsProvider>
			</PatientProvider>
		</AuthProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

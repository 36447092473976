import React, { createContext, useState, useContext } from "react";

const TransactionTotalContext = createContext();

export default function TransactionTotalProvider({ children }) {
	const [total, setTotal] = useState({});

	return (
		<TransactionTotalContext.Provider
			value={{
				total,
				setTotal,
			}}
		>
			{children}
		</TransactionTotalContext.Provider>
	);
}

export function useTransactionTotal() {
	const context = useContext(TransactionTotalContext);
	if (!context)
		throw new Error(
			"useTransactionTotal must be used within a transactionTotalProvider"
		);
	const { total, setTotal } = context;
	return { total, setTotal };
}

import React, { createContext, useState, useContext } from "react";

const PatientClaimContext = createContext();

export default function PatientProvider({ children }) {
	const [patient, setPatient] = useState({});

	return (
		<PatientClaimContext.Provider
			value={{
				patient,
				setPatient,
			}}
		>
			{children}
		</PatientClaimContext.Provider>
	);
}

export function usePatient() {
	const context = useContext(PatientClaimContext);
	if (!context)
		throw new Error("usePatient must be used within a PatientProvider");
	const { patient, setPatient } = context;
	return { patient, setPatient };
}

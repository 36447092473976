import React, { createContext, useState, useContext } from "react";

const ServiceContext = createContext();

export default function ServiceProvider({ children }) {
	const [service, setService] = useState({});

	return (
		<ServiceContext.Provider
			value={{
				service,
				setService,
			}}
		>
			{children}
		</ServiceContext.Provider>
	);
}

export function useService() {
	const context = useContext(ServiceContext);
	if (!context)
		throw new Error("useService must be used within a ServiceProvider");
	const { service, setService } = context;
	return { service, setService };
}

import { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import api from "../services/api";
import { login, logout } from "../services/auth";

export const Spinner = () => {
	const { setAuthenticated, setUser } = useAuth();
	// ====================VERIFY IF USER IS AUTHENTICATED================
	useEffect(() => {
		load();
	});

	async function load() {
		try {
			const response = await api.get("/profile");
			setUser(response.data.data);
			setAuthenticated(true);
			login(response.data.API_TOKEN);
		} catch (error) {
			logout();
			setAuthenticated(false);
		}
	}

	return (
		<div className="fallback-spinner h-100">
			<div className="loading">
				<div className="effect-1 effects"></div>
				<div className="effect-2 effects"></div>
				<div className="effect-3 effects"></div>
			</div>
		</div>
	);
};

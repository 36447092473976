import React, { createContext, useState, useContext } from "react";

const ClaimDetailsContext = createContext();

export default function ClaimDetailsProvider({ children }) {
	const [claim, setClaim] = useState({});

	return (
		<ClaimDetailsContext.Provider
			value={{
				claim,
				setClaim,
			}}
		>
			{children}
		</ClaimDetailsContext.Provider>
	);
}

export function useClaim() {
	const context = useContext(ClaimDetailsContext);
	if (!context)
		throw new Error("useClaim must be used within a ClaimDetailsProvider");
	const { claim, setClaim } = context;
	return { claim, setClaim };
}
